// 导入vue-router库中的创建路由
import { createRouter, createWebHashHistory } from 'vue-router'

// 创建路由信息表
const routes = [
    {
        path: '/',
        name: 'chat',
        component: () => import('../views/ChatView.vue'),
        meta: {
            title: 'AI问答', // 浏览器标题
            isHeadNavigation: true, // 是否显示tab
            keepAlive: false // 保持缓存
        }
    },
    {
        path: '/image',
        name: 'Image',
        component: () => import('../views/ImageView.vue'),
        meta: {
            title: 'AI绘图', // 浏览器标题
            isHeadNavigation: true, // 是否显示tab
            keepAlive: true // 保持缓存
        }
    },
    {
        path: '/aisqure',
        name: 'AISqure',
        component: () => import('../views/AISqureView.vue'),
        meta: {
            title: 'AI广场', // 浏览器标题
            isHeadNavigation: true, // 是否显示tab
            keepAlive: true // 保持缓存
        }
    },
    {
        path: '/answercollection',
        name: 'AnswerCollection',
        component: () => import('../views/AnswerColletionView.vue'),
        meta: {
            title: '回答收藏',
            isHeadNavigation: true,
            keepAlive: false
        }
    },
    {
        path: '/userprompts',
        name: 'UserPrompts',
        component: () => import('../views/UserPromptsView.vue'),
        meta: {
            title: '指令收藏',
            isHeadNavigation: true,
            keepAlive: false
        }
    },
    {
        path: '/servernews',
        name: 'ServerNews',
        component: () => import('../views/ServerNewsView.vue'),
        meta: {
            title: '消息',
            isHeadNavigation: false,
            keepAlive: false
        }
    },
    {
        path: '/purchase',
        name: 'Purchase',
        component: () => import('../views/PurchaseView.vue'),
        meta: {
            title: '购买',
            isHeadNavigation: false,
            keepAlive: false
        }
    },
    {
        path: '/priceview',
        name: 'PriceView',
        component: () => import('../views/PriceView.vue'),
        meta: {
            title: '网站说明',
            isHeadNavigation: false,
            keepAlive: false
        }
    },
    {
        path: '/personalcenter',
        name: 'PersonalCenter',
        component: () => import('../views/PersonalCenter.vue'),
        meta: {
            title: '个人中心',
            isHeadNavigation: false,
            keepAlive: false
        }
    },
    {
        path: '/controlpanel',
        name: 'ControlPanel',
        component: () => import('../views/Pannel/ControlPanel.vue'),
        meta: {
            title: '控制面板',
            isHeadNavigation: false,
            keepAlive: false
        }
    },
    {
        path: '/ordersrecords',
        name: 'OrdersRecords',
        component: () => import('../views/OrdersRecords.vue'),
        meta: {
            title: '购买记录',
            isHeadNavigation: false,
            keepAlive: false
        }
    }
];

// 创建路由实例
const router = createRouter({
    history: createWebHashHistory(), // 使用createRouter时，必须使用createWebHashHistory
    routes
})

// TODO 全局前置守卫
router.beforeEach(async (to) => {
    // TODO 设置浏览器Title
    document.title = '真智AI - ' + (to.meta.title ? to.meta.title : '')
})

// 导出实例
export default router