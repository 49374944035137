import { createApp } from 'vue'
import App from './App.vue'

// 导入vue-router库
import router from './router';

// 导入vuex库
import store from './store';

// 导入ElementPlus库
import ElementPlus from 'element-plus';
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import 'element-plus/theme-chalk/index.css';
import 'element-plus/theme-chalk/display.css';

// v-md-editor（代码块）
import VMdEditor from '@kangc/v-md-editor';
import '@kangc/v-md-editor/lib/style/base-editor.css';
import vuepressTheme from '@kangc/v-md-editor/lib/theme/vuepress.js';
import '@kangc/v-md-editor/lib/theme/style/vuepress.css';
import createMermaidPlugin from '@kangc/v-md-editor/lib/plugins/mermaid/cdn';
import '@kangc/v-md-editor/lib/plugins/mermaid/mermaid.css';

// Prism代码块样式
import Prism from 'prismjs';
// TODO 代码高亮
import 'prismjs/components/prism-json';
// TODO Copy Code 快捷复制代码
import createCopyCodePlugin from '@kangc/v-md-editor/lib/plugins/copy-code/index';
import '@kangc/v-md-editor/lib/plugins/copy-code/copy-code.css';


// Import the FontAwesome core
import { library } from '@fortawesome/fontawesome-svg-core';
// Import the FontAwesome icon component
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
// Import specific icons
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';

// Add the icons to the library
library.add(fas, far, fab);



VMdEditor.use(vuepressTheme, {
    Prism
});
VMdEditor.use(createCopyCodePlugin(),createMermaidPlugin());

// 导入全局颜色
import './assets/styles/variables.css';

// 创建app实例
const app = createApp(App);
app.use(VMdEditor);
app.use(router);
app.use(store);
app.use(ElementPlus);
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}
app.component("font-awesome-icon", FontAwesomeIcon);
app.mount('#app');