import { GetUserInfo_ } from "../../api/ZH_UserAPI";
import userStore from "@/store";
export async function GetUserInfo() {
  try {
    const response = await GetUserInfo_();
    const userInfo = response.data.userInfo;

    // 令牌更新
    if (response.data.isChange) {
      localStorage.setItem("userToken", response.data.userToken);
    }

    // 存储临时状态变量(页面刷新后清空)
    userStore.commit("setuserInfo", userInfo);
    // 存入缓存，长期有效
    localStorage.setItem('userInfo', JSON.stringify(userInfo));
    return;
  } catch (error) {
    console.log("获取用户信息失败：", error);
    throw error;
  }
}
