export function DateFormat(date) {
  const isoString = date;
  const dateObject = new Date(isoString);
  const dateFormat = new Intl.DateTimeFormat("zh-CN", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  });
  const formattedDate = dateFormat.format(dateObject);
  return formattedDate;
}
