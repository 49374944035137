<template>
  <div class="content-box">
    <!-- 图像缓存列表 -->
    <div class="chat-list-box">
      <div class="chat-list-scrollbar">
        <!-- 图像缓存项 -->
        <div
          class="chat-list-item"
          v-for="(item, index) in userAlbums.array"
          :key="index"
          :style="{
            backgroundColor:
              userAlbums.chosenIndex === item.albumID
                ? 'var(--theme-light-B)'
                : '',
          }"
          @mouseleave="isEdit = false"
          @click="GetUserImgList(item)"
        >
          <!-- 左边显示标题和时间 -->
          <di class="chat-list-text">
            <div class="chat-list-title">{{ item.albumTitle }}</div>
            <!-- 创建时间 -->
            <div class="chat-list-time">
              {{ DateFormat(item.createdTime) }}
            </div>
          </di>
          <!-- 右边的编辑按钮 -->
          <div
            class="chat-list-btn-box"
            :class="{
              'chat-list-btn-box-chosen':
                userAlbums.chosenIndex === item.albumID && isEdit,
            }"
            v-if="userAlbums.chosenIndex === item.albumID"
          >
            <!-- 工具栏展开按钮 -->
            <el-icon class="chat-list-btn" @mouseover="isEdit = true">
              <MoreFilled />
            </el-icon>
            <!-- 工具栏 -->
            <div
              class="chat-func-list-btn"
              v-if="userAlbums.chosenIndex === item.albumID && isEdit"
            >
              <el-icon
                class="chat-list-btn"
                @click="ChangeTitle(item.albumTitle)"
              >
                <EditPen />
              </el-icon>
              <el-icon class="chat-list-btn" @click="DelUserAlbums(item)">
                <Delete />
              </el-icon>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 重命名 -->
    <ConfirmDialog
      v-if="isShowRename"
      @cancel="isShowRename = false"
      @confirm="ChangeUserAlbumsTitle()"
    >
      <template #content>
        <div class="input-bar">
          <el-icon class="input-bar-icon">
            <ChatDotSquare />
          </el-icon>
          <el-input
            v-model="albumTitleInput"
            placeholder="请输入图像名称"
            show-word-limit
            maxlength="20"
            @keyup.enter="ChangeUserAlbumsTitle()"
          />
        </div>
      </template>
    </ConfirmDialog>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from "vue";
import { ElMessage, ElNotification } from "element-plus";
import userStore from "../store/index.js";
import {
  GetUserAlbums_,
  DelUserAlbums_,
  ChangeUserAlbumsTitle_,
  GetUserImgList_,
} from "../../api/SG_UserAPI";
import { DateFormat } from "../utils/DateFormat.js";
import ConfirmDialog from "./ConfirmDialog.vue";

/* ---------其他功能开始--------- */
// 是否处于编辑状态
let isEdit = ref(false);
/* ---------其他功能结束--------- */

/* ---------用户图像数据操作开始--------- */
let userAlbums = computed(() => userStore.getters["getuserAlbums"]);

// 获取用户画册
async function GetUserAlbums() {
  try {
    const response = await GetUserAlbums_();
    console.log("图像缓存为：", response.data);
    userStore.commit("setuserAlbums_array", response.data);

    if (response.data.length === 0) {
      ElNotification.info("你没有任何画册");
    }
  } catch (error) {
    ElMessage.error("获取图像失败");
    console.log(error);
  }
}

// 获取用户图像列表
async function GetUserImgList(item) {
  try {
    if (userStore.getters["getisLoading"]) {
      ElNotification.error("图像中，无法切换图像");
    }

    // 清空当前显示的图像列表
    userStore.commit("setisChanging", true);
    userStore.commit("setuserImgList", []);


    // 获取画册对应的图像列表
    const { albumID } = item;
    const response = await GetUserImgList_({ albumID });
    console.log("图像列表", response.data);

    // 存储当前图像缓存
    userStore.commit("setuserImgList", response.data);
    userStore.commit("setuserAlbums_chosenIndex", item.albumID);

    // 更换完毕
    userStore.commit("setisChanging", false);
  } catch (error) {
    ElMessage.error("获取图像列表失败");
    console.log(error);
  }
}

// 删除用户画册
async function DelUserAlbums(item) {
  try {
    const { albumID } = item;

    await DelUserAlbums_({ albumID });

    // 更新视图
    await GetUserAlbums();
    userStore.commit("setuserImgList", []);
    userStore.commit("setuserAlbums_chosenIndex",null)
  } catch (error) {
    ElMessage.error("删除图像失败");
    console.log(error);
  }
}

// 显示重命名区域
let isShowRename = ref(false);
// 画册标题输入
let albumTitleInput = ref("");
function ChangeTitle(albumTitle) {
  isShowRename.value = true;
  albumTitleInput.value = albumTitle;
}
// 修改用户图像缓存标题
async function ChangeUserAlbumsTitle() {
  try {
    console.log("修改标题");
    if (albumTitleInput.value == "") {
      return;
    }

    const data = {
      albumID: userAlbums.value.chosenIndex,
      albumTitle: albumTitleInput.value,
    };

    await ChangeUserAlbumsTitle_(data);

    // 更新视图
    await GetUserAlbums();
    isShowRename.value = false;
  } catch (error) {
    console.log(error);
    ElMessage.error("修改图像标题失败");
  }
}
/* ---------用户图像数据操作结束--------- */

/* ---------生命周期钩子开始--------- */
onMounted(() => {
  // 如果有用户信息
  if (localStorage.getItem("userInfo")) {
    GetUserAlbums();
  }
});
/* ---------生命周期钩子结束--------- */
</script>

<style scoped>
.content-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: var(--theme-light-A);

  .chat-list-box {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;

    .chat-list-scrollbar {
      width: 100%;
      display: flex;
      height: 100%;
      flex-direction: column;
      background-color: var(--theme-light-C);
      padding: 0 0.5rem;
      box-sizing: border-box;
    }

    .chat-list-item {
      display: flex;
      align-items: center;
      width: 100%;
      border-radius: 1rem;
      cursor: pointer;
      padding: 0.25rem 0.5rem 0.25rem 1rem;
      margin-bottom: 0.25rem;
      box-sizing: border-box;
      transition: all 0.25s;

      .chat-list-text {
        width: 100%;
        font-size: 1rem;
        overflow: auto;

        .chat-list-title {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          margin-bottom: 0.5rem;
        }

        .chat-list-time {
          color: var(--theme-light-A);
          font-size: 0.8rem;
        }
      }
    }

    .chat-list-item:hover {
      background-color: var(--theme-light-B);
      transform: scale(1.025);
    }
  }
}

.chat-list-btn-box {
  display: flex;
  position: relative;
  align-items: center;
}

.chat-list-btn-box-chosen {
  background-color: var(--theme-light-C);
  border-radius: 1rem;
}

.chat-list-btn {
  padding: 0.25rem;
  font-size: 1.25rem;
  border-radius: 0.5rem;
  margin: 0.5rem;
  color: var(--theme-light-A);
  transition: all 0.25s;
}

.chat-list-btn:hover {
  transform: scale(1.1);
  background-color: var(--theme-light-B);
}

.chat-func-list-btn {
  display: flex;
  position: absolute;
  border-radius: 1rem;
  background-color: var(--theme-light-C);
  right: 100%;
  margin-right: 0.5rem;
}

.input-bar {
  background-color: var(--theme-light-D);
  border: 0.05rem solid var(--theme-light-B);
  font-size: 1rem;
  display: flex;
  align-items: center;
  width: 100%;
  border-radius: 1.5rem;
  color: var(--theme-light-A);
  margin-bottom: 0.5rem;
  box-sizing: border-box;
  padding-right: 1rem;

  .input-bar-icon {
    padding: 0.5rem 1rem;
    font-size: 1.5rem;
  }
}

.bottom-func-bar {
  display: flex;

  .bottom-func-btn {
    border: 0.05rem solid var(--theme-light-B);
    transition: all 0.25s;
    color: var(--theme-light-A);
    cursor: pointer;
    padding: 0.5rem 2rem;
    border-radius: 1.5rem;
    margin: 0 1rem;
    margin-top: 1rem;
  }

  .bottom-func-btn:hover {
    background-color: var(--theme-light-B);
  }
}

/* 输入框样式 */
::v-deep .el-input__inner {
  box-shadow: none;
  background-color: var(--theme-light-D);
}

::v-deep .el-input__wrapper {
  width: 100%;
  box-shadow: none;
  background-color: var(--theme-light-D);
  padding: 0;
}

::v-deep .el-input__wrapper.is-focus {
  box-shadow: none;
}

::v-deep .el-input .el-input__count .el-input__count-inner {
  background-color: var(--theme-light-D);
  font-size: 1rem;
  padding: 0 0.5rem;
}

@media only screen and (max-width: 450px) {
}
</style>
