<template>
  <div class="login-dialogue">
    <div class="main">
      <header>
        <div class="logo">
          <img class="logoimage" src="../assets/web_logo.png" alt="Logo" />
          <span>真智AI</span>
        </div>
        <a
          href="https://superlovefuture.com/"
          class="build-button"
          target="_blank"
        >
          由超艾纬来开发
        </a>
      </header>

      <P>
        <span>和<strong>真智AI</strong>一起</span>
        <div id="display"></div>
      </P>

      <div class="login" v-if="isShowLogin">
        <div class="form-container">
          <div class="wechat-login">
            <h2>微信登录</h2>
            <img :src="qrCodeUrl" alt="微信二维码" />
            <div>扫描二维码关注公众号登录</div>
          </div>
          <div class="email-login">
            <h2>邮箱登录</h2>
            <form @submit.prevent="EmailLogin()">
              <label for="email">邮箱:</label>
              <input
                type="email"
                id="email"
                v-model="inputForm.emailInput"
                required
              /><br />
              <label for="password">密码:</label>
              <input
                type="password"
                id="password"
                v-model="inputForm.passwordInput"
                required
              />
              <div class="submit">
                <input type="submit" value="登录" @click="EmailLogin()" />
                <input
                  type="button"
                  value="注册"
                  @click="(isShowRegister = true), EmailRegister()"
                />
              </div>
            </form>
          </div>
        </div>
        <div>
          <P class="privacy-text">
            继续即表示您同意真智AI的
            <a href="https://superlovefuture.com/privacy.html" target="_blank"
              >消费者条款</a
            >
            和
            <a href="https://superlovefuture.com/privacy.html" target="_blank"
              >使用政策</a
            >，并认可他们的
            <a
              href="https://superlovefuture.com/privacy.html#privacy"
              target="_blank"
              >隐私政策</a
            >.
          </P>
        </div>
      </div>

      <div class="new-feature">
        <span>NEW</span>认识一下TOP-4o，我们迄今为止功能最强大的模型。
        <a href="#">了解更多</a>
      </div>

      <footer>
        真智AI是一款为工作而构建的下一代AI助手，经过训练以确保安全、准确和可靠。
      </footer>

      <div class="bottom">
        © 2024
        <a
          href="https://superlovefuture.com"
          style="text-decoration: none"
          target="_blank"
          >超艾纬来</a
        >
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { ElNotification, ElMessage } from "element-plus";
import {
  // EmailRegister_,
  EmailLogin_,
  // GetEmailCheckCode_,
  GetWeChatQRCode_,
  CheckSubscriptionStatus_,
  // ForgetUserPsw_,
} from "../../api/ZH_UserAPI";
import { GetUserInfo } from "@/utils/GetUserInfo";

/* ---------邮箱功能开始--------- */
let isShowRegister = ref(false);
let isShowLogin = ref(true);

let inputForm = ref({
  emailInput: "",
  passwordInput: "",
  nameInput: "",
  checkcodeInput: "",
});
// 邮箱登录
async function EmailLogin() {
  try {
    const data = {
      userEmail: inputForm.value.emailInput,
      userPsw: inputForm.value.passwordInput,
    };
    const response = await EmailLogin_(data);

    // 将用户令牌存入中
    localStorage.setItem("userToken", response.data.userToken);
    // 通过令牌获取用户信息
    await GetUserInfo();

    ElNotification({
      title: "登录成功",
      message: "快登录体验True Science AI吧！",
      type: "success",
    });

    location.reload();
  } catch (error) {
    if (error.response.status === 404) {
      ElMessage.error("账号或密码错误");
    } else {
      ElMessage.error("服务器连接失败！");
    }
    console.error(error);
  }
}

// let isShowResetPsd = ref(false);
// 忘记用户密码
// async function ForgetUserPsw() {
//   try {
//     if (
//       !inputForm.value.emailInput ||
//       !inputForm.value.passwordInput ||
//       !inputForm.value.checkcodeInput
//     ) {
//       return ElMessage.error("请补充完信息！");
//     }
//     if (inputForm.value.passwordInput.length < 11) {
//       return ElMessage.error("密码必须大于等于11位");
//     }

//     const data = {
//       userEmail: inputForm.value.emailInput,
//       userPsw: inputForm.value.passwordInput,
//     };
//     await ForgetUserPsw_(data);

//     ElNotification({
//       title: "修改成功",
//       message: "赶快去登录吧",
//       type: "success",
//     });
//     isShowResetPsd.value = false;
//   } catch (error) {
//     ElMessage.error("服务器连接失败");
//     console.log(error);
//   }
// }

// let dutyChecked = ref(false);
// // 邮箱注册功能
// async function EmailRegister() {
//   try {
//     if (
//       !inputForm.value.nameInput ||
//       !inputForm.value.emailInput ||
//       !inputForm.value.passwordInput ||
//       !dutyChecked.value
//     ) {
//       return ElMessage.error("请补充完信息");
//     }
//     if (
//       inputForm.value.checkcodeInput != checkCode.value ||
//       !inputForm.value.checkcodeInput
//     ) {
//       return ElMessage.error("验证码错误");
//     }
//     if (inputForm.value.passwordInput.length < 11) {
//       return ElMessage.error("密码必须大于等于11位");
//     }

//     const data = {
//       userName: inputForm.value.nameInput,
//       userEmail: inputForm.value.emailInput,
//       userPsd: inputForm.value.passwordInput,
//     };
//     const response = await EmailRegister_(data);

//     if (response.status === 201) {
//       ElNotification({
//         title: "注册成功",
//         message: "快去登录吧",
//         type: "success",
//       });
//       location.reload();
//     }
//   } catch (error) {
//     console.error(error);
//     if (error.response.status === 409) {
//       return ElMessage.error("用户已存在");
//     }
//   }
// }
/* ---------邮箱功能结束--------- */

/* ---------微信功能开始--------- */
let qrCodeUrl = ref(null);
// 微信扫码登录
async function WeChatLogin() {
  try {
    console.log("微信扫码登录");
    // 轮询次数清零
    attemptCount.value = 0;

    // 获取二维码票据
    const response = await GetWeChatQRCode_();
    const qrCodeTicket = response.data.ticket; // 假设后端返回二维码地址
    qrCodeUrl.value = `https://mp.weixin.qq.com/cgi-bin/showqrcode?ticket=${qrCodeTicket}`;

    // 轮询是否关注
    await CheckSubscriptionStatus(qrCodeTicket);
  } catch (error) {
    ElMessage.error("服务器连接失败!");
    console.error(error);
  }
}

// async function WeChatAuthLogin() {
//   try {
//     console.log("微信授权登录");
//     let appID = "wxc045fb16532bae46";
//     // 重定向地址
//     let redirectUri = "https://truescienceai.com";
//     window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appID}&redirect_uri=${encodeURIComponent(
//       redirectUri
//     )}&response_type=code&scope=snsapi_userinfo&state=BinsGroup#wechat_redirect`;
//   } catch (error) {
//     console.log(error);
//     ElMessage.error("服务器连接失败");
//   }
// }

// 是否订阅
let isSubscribe = ref(false);
// 初始轮询间隔设为5秒
let pollInterval = ref(5000);
// 最大尝试次数
const maxAttempt = ref(24);
// 当前尝试次数
let attemptCount = ref(0);
// 检查微信公众号订阅状态
async function CheckSubscriptionStatus(qrCodeTicket) {
  try {
    const data = { qrCodeTicket };
    const response = await CheckSubscriptionStatus_(data);
    isSubscribe.value = response.data.isSubscribe;
    console.log(response.data);

    // 如果用户已订阅
    if (isSubscribe.value) {
      localStorage.setItem("userToken", response.data.userToken);
      await GetUserInfo();

      ElNotification({
        title: "登录成功",
        message: "快登录体验True Science AI吧！",
        type: "success",
      });
      location.reload();
      return;
    } else if (attemptCount.value < maxAttempt.value) {
      attemptCount.value++;

      await new Promise((resolve) => setTimeout(resolve, pollInterval.value));
      CheckSubscriptionStatus(qrCodeTicket);
    } else {
      attemptCount.value = 0;
    }
  } catch (error) {
    if (attemptCount.value < maxAttempt.value) {
      attemptCount.value++;

      await new Promise((resolve) => setTimeout(resolve, pollInterval.value));
      CheckSubscriptionStatus(qrCodeTicket);
    } else {
      attemptCount.value = 0;
    }
    ElMessage.error("服务器连接失败!");
    console.error(error);
  }
}
/* ---------微信功能结束--------- */

/* ---------其他功能开始--------- */
// 验证码倒计时
// let countdown = ref(0);
// let timer = ref(null);
// function StartCountDown() {
//   countdown.value = 120;

//   timer.value = setInterval(() => {
//     if (countdown.value > 0) {
//       countdown.value--;
//     } else {
//       clearInterval(timer.value);
//       timer.value = null;

//       checkCode.value = "";
//     }
//   }, 1000);
// }

// 获取验证码
// let checkCode = ref("");
// async function GetCheckCode() {
//   try {
//     if (!inputForm.value.emailInput || !inputForm.value.passwordInput) {
//       return ElMessage.error("请输入邮箱或者密码");
//     }
//     if (inputForm.value.passwordInput.length < 11) {
//       return ElMessage.error("密码必须大于等于11位");
//     }

//     StartCountDown();

//     const data = {
//       userEmail: inputForm.value.emailInput,
//     };
//     const response = await GetEmailCheckCode_(data);
//     ElMessage.success("发送成功");

//     checkCode.value = response.data;
//   } catch (error) {
//     ElMessage.error("服务器连接失败");
//     console.error(error);
//   }
// }

// 判断设备
function IsMobileDevice() {
  return /Mobi|Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );
}
/* ---------其他功能结束--------- */

/* ---------生命周期函数钩子开始--------- */
onMounted(() => {
  if (IsMobileDevice()) {
    console.log("当前设备是移动设备");
  } else {
    console.log("当前设备是PC设备");
    // 先执行一次 WeChatLogin
    WeChatLogin();
    // 设置每隔120秒执行一次 WeChatLogin
    setInterval(() => {
      WeChatLogin();
    }, 120000); // 120000毫秒 = 120秒
  }
});
/* ---------生命周期函数钩子结束--------- */
</script>

<style scoped>
.login-dialogue {
  width: 100%;
  height: 100%;
  background-color: var(--theme-light-C);
  color: var(--theme-light-A);
  display: flex;
  align-items: center;
  flex-direction: column;
}
.main {
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  align-items: center;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  max-width: 72rem;
  width: 100%;

  flex-grow: 1;
}
header {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
}
.logo {
  display: flex;
  align-items: center;
  font-size: 30px;
  font-weight: bold;
  color: #0f0f0d;
}

.logoimage {
  width: 50px;
  margin-right: 10px;
}

.build-button {
  font-size: 14px;
  font-weight: 700;
  background-color: none;
  color: #535146;
  padding: 10px 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  text-decoration: none;
  cursor: pointer;
}
@media only screen and (max-width: 768px) {
  .logo {
    font-size: 20px;
  }
}
p {
  font-size: 60px;
  text-align: center;
  margin-top: 30px;
  margin-bottom: 0px;
  letter-spacing: 0.1em;
}
#display {
  font-size: 60px;
  text-align: center;
  letter-spacing: 0.1em;
  /* height: 20px; */
  /* margin-bottom: 30px; */
}
.char {
  opacity: 0;
  transition: opacity 0.5s; /* 控制透明度变化的时间 */
}
@media only screen and (max-width: 768px) {
  p,
  #display {
    font-size: 48px;
    margin-bottom: 0px;
  }
}
.login {
  display: flex;
  flex-direction: column;
  max-width: 50rem;
  margin: 10px 1rem;
  /* padding: 20px; */
  border-radius: 8px; /* 圆角边框 */
  background-color: #f3f1eb; /* 背景色 */
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1); /* 阴影效果 */
}
.form-container {
  display: flex;
}
.wechat-login,
.email-login {
  flex: 1;
  margin: 1rem 0;
  padding: 0 2.5rem;
  /* border-right: 1px solid #ddd; */
  text-align: center; /* 文本居中 */
}
@media only screen and (max-width: 768px) {
  .wechat-login h2,
  .email-login h2 {
    font-size: 20px;
  }
}
@media only screen and (max-width: 768px) {
  .wechat-login,
  .email-login {
    padding: 0 1rem;
  }
}
.wechat-login {
  border-right: 1px solid #ddd;
}

.wechat-login h2,
.email-login h2 {
  color: #333; /* 标题颜色 */
  margin-bottom: 10px; /* 标题下方间距 */
}
.wechat-login img {
  width: 80%; /* 使二维码图片自适应宽度 */
  max-width: 150px;
  height: auto;
  margin-bottom: 20px; /* 图片下方间距 */
}
@media only screen and (max-width: 768px) {
  .wechat-login image {
    width: 100px;
  }
}
form {
  /* width: 100px; */
  text-align: left;
}
input[type="email"],
input[type="password"] {
  width: 80%; /* 输入框占满宽度 */
  padding: 10px;
  margin: 10px 0; /* 上下间距 */
  border: 1px solid #ddd; /* 边框 */
  border-radius: 4px; /* 圆角 */
  font-size: 16px;
  /* margin: ; */
}
input[type="submit"] {
  background-color: #ba5b38; /* 按钮背景色 */
  color: white; /* 按钮文字颜色 */
  border: none; /* 去除边框 */
  border-radius: 4px; /* 圆角 */
  padding: 5px;
  cursor: pointer; /* 鼠标悬停变化 */
  width: 50%; /* 按钮宽度 */
  font-family: Grenette, qingyuan, huakangshaonv, KaiTi, 楷体, STKaiti,
    "Microsoft YaHei", 微软雅黑, serif;
  font-size: 16px;
}
input[type="submit"]:hover {
  background-color: #ba5b38; /* 按钮悬停状态颜色 */
}
.submit {
  width: 90%;
  display: flex;
  justify-content: space-between;
}
.submit input {
  width: 40%;
  text-align: center;
}
.privacy-text {
  font-size: 12px;
  color: #8a8a8a;
  margin-top: 0.5rem;
  margin-left: 1rem;
  margin-right: 1rem;
  margin-bottom: 0.5rem;
}
.privacy-text a {
  font-size: 12px;
  color: #8a8a8a;
}
.privacy-text a:hover {
  font-size: 12px;
  color: #0c0000;
}

.new-feature {
  font-size: 16px;
  color: #29261b;
  background-color: transparent;
  padding: 10px;
  border: 1px solid hsl(251, 61%, 72.2%);
  border-radius: 10px;
  margin-left: 1rem;
  margin-right: 1rem;
  margin-top: 50px;
  text-align: center;
}
.new-feature a {
  display: inline-block;
  color: #535146;
  padding: 2px;
  border-bottom: 1px solid hsl(48 12.5% 39.2%);
  text-decoration: none;
}
.new-feature span {
  font-size: 12px;
  font-weight: 500px;
  color: white;
  padding: 6px 10px;
  margin-right: 10px;
  border: 1px solid hsl(48 12.5% 39.2%);
  border-radius: 8px;
  text-decoration: none;
  background-color: #6657a9;
}
.new-feature a:hover {
  display: inline-block;
  color: #29261b;
  padding: 2px;
  border-bottom: 1px solid hsl(48 12.5% 39.2%);
  text-decoration: none;
}
footer {
  max-width: 36rem;
  font-size: 24px;
  margin-top: 50px;
  text-align: center;
  margin-left: 1rem;
  margin-right: 1rem;
  color: #3d3929;
}
@media only screen and (max-width: 768px) {
  footer {
    font-size: 20px;
    margin-top: 30px;
  }
}
.bottom {
  margin-top: 20px;
  color: grey;
}
.bottom a {
  text-decoration: none;
  color: grey;
}

/* 输入框样式 */
::v-deep .el-input__inner {
  box-shadow: none;
  background-color: var(--theme-light-D);
}

::v-deep .el-input__wrapper {
  width: 100%;
  box-shadow: none;
  background-color: var(--theme-light-D);
  padding: 0;
}

::v-deep .el-input__wrapper.is-focus {
  box-shadow: none;
}
::v-deep .el-input .el-input__count .el-input__count-inner {
  background-color: var(--theme-light-D);
  font-size: 1rem;
  padding: 0 0.5rem;
}

/* 多选框样式 */
::v-deep .el-checkbox.el-checkbox--large .el-checkbox__label {
  font-size: 1rem;
  color: var(--theme-light-D);
}

/* 手机适配 */
@media only screen and (max-width: 450px) {
  .login-box-back {
    .login-dialogue {
      .title:nth-child(1) {
        font-size: 5rem;
        animation: marquee 7s linear infinite;
      }
      .title:nth-child(2) {
        font-size: 5rem;
        animation: marquee 6s linear infinite;
      }
      .title:nth-child(3) {
        font-size: 5rem;
        animation: marquee 8s linear infinite;
      }
    }
  }

  @keyframes marquee {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(-100%);
    }
  }
}
</style>
