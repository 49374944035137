<template>
  <div class="notice-box-back" @click="Close()" @keydown.esc="HandleEsc()">
    <div class="notice-content" @click.stop>
      <p class="notice-title">微信扫码关注公众号</p>
      <img class="wxgzh-img" src="../assets/wxgzh.png" />
    </div>
  </div>
</template>

<script setup>
import { onMounted, onUnmounted, defineEmits } from "vue";

const emit = defineEmits(["close"]);

function Close() {
  emit("close");
}

function HandleEsc(event) {
  if (event.key === "Escape") {
    Close();
  }
}

onMounted(() => {
  document.addEventListener("keydown", HandleEsc);
});

onUnmounted(() => {
  document.removeEventListener("keydown", HandleEsc);
});
</script>

<style scoped>
.notice-box-back {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  padding: 0.5rem;
  box-sizing: border-box;
}

.notice-content {
  display: flex;
  flex-direction: column;
  background-color: var(--theme-light-C);
  border-radius: 1.5rem;
  padding: 0.5rem;
  width: 100%;
  max-width: 60rem;
}

.notice-title {
  color: var(--theme-light-A);
  font-size: 2rem;
  text-align: center;
  margin-bottom: 0.5rem;
  margin-top: 0;
  font-weight: bold;
}

.wxgzh-img {
  width: 100%;
  border-radius: 1rem;
}
</style>
