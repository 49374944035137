// 新加坡服务器操作API接口
import axios from "axios";
import userStore from "@/store";
import { ElMessage } from "element-plus";

// 创建axios示例
const request = axios.create({
  baseURL: process.env.VUE_APP_SG_NODE_BASE_URL,
});

// 响应拦截器
request.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    console.log("错误：", error);
    // 登录信息过期，退出登录
    if (error.response.status === 403 || error.response.status === 401) {
      ElMessage.error("登录信息，请重新登陆！");
      userStore.commit("setuserInfo", null);
      localStorage.removeItem("userToken");
      localStorage.removeItem("userInfo");
      location.reload();
    }
    throw error;
  }
);
/* ---------服务器其他功能开始--------- */
// 获取服务器消息
export function GetServerNews_() {
  const userToken = localStorage.getItem("userToken");
  return request({
    url: "/func/servernews/GetServerNews",
    method: "POST",
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
  });
}

// 添加服务器消息
export function AddServerNews_(data) {
  const userToken = localStorage.getItem("userToken");
  return request({
    url: "/func/servernews/AddServerNews",
    method: "POST",
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
    data,
  });
}

// 获取用户已读消息ID
export function GetUserNewsReadID_() {
  const userToken = localStorage.getItem("userToken");
  return request({
    url: "/func/servernews/GetUserNewsReadID",
    method: "POST",
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
  });
}

// 已读消息
export function ReadNews_(data) {
  const userToken = localStorage.getItem("userToken");
  return request({
    url: "/func/servernews/ReadNews",
    method: "POST",
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
    data,
  });
}
/* ---------服务器其他功能结束--------- */

/* ---------服务器控制面板功能开始--------- */
// 获取服务器配置信息
export function GetServerConfig_() {
  const userToken = localStorage.getItem("userToken");
  return request({
    url: "/func/controlpanel/GetServerConfig",
    method: "POST",
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
  });
}

// 更新服务器配置信息
export function UpdateServerConfig_(data) {
  const userToken = localStorage.getItem("userToken");
  return request({
    url: "/func/controlpanel/UpdateServerConfig",
    method: "POST",
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
    data,
  });
}

// 清空服务器消息
export function DelServerNews_() {
  const userToken = localStorage.getItem("userToken");
  return request({
    url: "/func/controlpanel/DelServerNews",
    method: "POST",
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
  });
}

// 获取所有文件数据
export function GetAllFilesData_() {
  const userToken = localStorage.getItem("userToken");
  return request({
    url: "/func/controlpanel/GetAllFilesData",
    method: "POST",
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
  });
}

// 删除过期文件
export function DeleteExpiredFiles_() {
  const userToken = localStorage.getItem("userToken");
  return request({
    url: "/func/controlpanel/DeleteExpiredFiles",
    method: "POST",
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
  });
}
/* ---------服务器控制面板功能结束--------- */

/* ---------AIs功能开始--------- */
// 获取AIs内容
export function GetAIsContent_() {
  const userToken = localStorage.getItem("userToken");
  return request({
    url: "/func/ais/GetAIsContent",
    method: "POST",
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
  });
}
/* ---------AIs功能结束--------- */
