<template>
  <div class="content-box">
    <!-- 对话缓存列表 -->
    <div class="chat-list-box">
      <div class="chat-list-scrollbar">
        <!-- 对话缓存项 -->
        <div
          class="chat-list-item"
          v-for="(item, index) in dialoguesCache.array"
          :key="index"
          :style="{
            backgroundColor:
              dialoguesCache.chosenIndex === item.dialogueID
                ? 'var(--theme-light-B)'
                : '',
          }"
          @mouseleave="isEdit = false"
        >
          <!-- 左边显示标题和时间 -->
          <di class="chat-list-text" @click="GetUserConversationList(item)">
            <div class="chat-list-title">{{ item.dialogueTitle }}</div>
            <!-- 创建时间 -->
            <div class="chat-list-time">
              {{ DateFormat(item.createdTime) }}
            </div>
          </di>
          <!-- 右边的编辑按钮 -->
          <div
            class="chat-list-btn-box"
            :class="{
              'chat-list-btn-box-chosen':
                dialoguesCache.chosenIndex === item.dialogueID && isEdit,
            }"
            v-if="dialoguesCache.chosenIndex === item.dialogueID"
          >
            <!-- 工具栏展开按钮 -->
            <el-icon class="chat-list-btn" @mouseover="isEdit = true">
              <MoreFilled />
            </el-icon>
            <!-- 工具栏 -->
            <div
              class="chat-func-list-btn"
              v-if="dialoguesCache.chosenIndex === item.dialogueID && isEdit"
            >
              <el-icon
                class="chat-list-btn"
                @click="ChangeTitle(item.dialogueTitle)"
              >
                <EditPen />
              </el-icon>
              <el-icon
                class="chat-list-btn"
                @click="DelUserDialoguesCache(item)"
              >
                <Delete />
              </el-icon>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 重命名 -->
    <ConfirmDialog
      v-if="isShowRename"
      @cancel="isShowRename = false"
      @confirm="ChangeUserDialoguesTitle()"
    >
      <template #content>
        <div class="input-bar">
          <el-icon class="input-bar-icon">
            <ChatDotSquare />
          </el-icon>
          <el-input
            v-model="dialogueTitleInput"
            placeholder="请输入对话名称"
            show-word-limit
            maxlength="20"
            @keyup.enter="ChangeUserDialoguesTitle()"
          />
        </div>
      </template>
    </ConfirmDialog>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from "vue";
import { ElMessage } from "element-plus";
import userStore from "../store/index.js";
import {
  GetUserDialoguesCache_,
  DelUserDialoguesCache_,
  ChangeUserDialoguesTitle_,
  GetUserConversationList_,
} from "../../api/SG_UserAPI";
import { DateFormat } from "../utils/DateFormat.js";
import ConfirmDialog from "./ConfirmDialog.vue";

/* ---------其他功能开始--------- */
// 显示重命名区域
let isShowRename = ref(false);

// 是否处于编辑状态
let isEdit = ref(false);
/* ---------其他功能结束--------- */

/* ---------用户对话数据操作开始--------- */
let dialoguesCache = ref({
  chosenIndex: computed(
    () => userStore.getters["getdialoguesCache"].chosenIndex
  ),
  array: computed(() => userStore.getters["getdialoguesCache"].array),
});

// 获取用户对话缓存
async function GetUserDialoguesCache() {
  try {
    const response = await GetUserDialoguesCache_();
    console.log("对话缓存为：", response.data);
    userStore.commit("setdialoguesCache_array", response.data);

    if (response.data.length === 0) {
      ElMessage.info("你没有任何对话数据");
    }
  } catch (error) {
    ElMessage.error("获取对话失败");
    console.log(error);
  }
}

// 删除对话缓存
async function DelUserDialoguesCache(item) {
  try {
    const data = {
      dialogueID: item.dialogueID,
      threadID: item.threadID,
      apiKey: item.apiKey,
    };

    // 删除对话缓存
    await DelUserDialoguesCache_(data);

    // 更新视图
    await GetUserDialoguesCache();
    userStore.commit("setuserConversationList", []);
    userStore.commit("setcurrentdialoguesCacheInfo", {});
    userStore.commit("setdialoguesCache_chosenIndex", null);
  } catch (error) {
    ElMessage.error("删除对话失败");
    console.log(error);
  }
}

let dialogueTitleInput = ref("");
function ChangeTitle(dialogueTitle) {
  isShowRename.value = true;
  dialogueTitleInput.value = dialogueTitle;
}
// 修改用户对话缓存标题
async function ChangeUserDialoguesTitle() {
  try {
    console.log("修改标题");
    if (dialogueTitleInput.value == "") {
      return;
    }

    const data = {
      dialogueID: dialoguesCache.value.chosenIndex,
      dialogueTitle: dialogueTitleInput.value,
    };

    await ChangeUserDialoguesTitle_(data);

    // 更新视图
    await GetUserDialoguesCache();
    isShowRename.value = false;
  } catch (error) {
    ElMessage.error("修改对话标题失败");
    console.log(error);
  }
}

// 获取用户对话列表
async function GetUserConversationList(item) {
  try {
    if (userStore.getters.getisLoading) {
      ElMessage.error("对话中，无法切换对话");
    }
    // 清空当前显示的对话列表
    userStore.commit("setisChanging", true);
    userStore.commit("setuserConversationList", []);

    // 将当前对话缓存的信息存入userStore
    userStore.commit("setcurrentdialoguesCacheInfo", item);
    console.log(item);

    // 获取对话缓存对应的对话列表
    const data = { dialogueID: item.dialogueID };
    const response = await GetUserConversationList_(data);
    console.log(response.data);

    // 存储当前对话缓存和当前选中的对话ID
    userStore.commit("setuserConversationList", response.data);
    userStore.commit("setdialoguesCache_chosenIndex", item.dialogueID);

    // 更换完毕
    userStore.commit("setisChanging", false);
  } catch (error) {
    ElMessage.error("获取对话列表失败");
    console.log(error);
  }
}
/* ---------用户对话数据操作结束--------- */

/* ---------生命周期钩子开始--------- */
onMounted(() => {
  // 如果有用户信息
  if (localStorage.getItem("userInfo")) {
    GetUserDialoguesCache();
  }
});
/* ---------生命周期钩子结束--------- */
</script>

<style scoped>
.content-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: var(--theme-light-A);

  .chat-list-box {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;

    .chat-list-scrollbar {
      width: 100%;
      display: flex;
      height: 100%;
      flex-direction: column;
      background-color: var(--theme-light-C);
      padding: 0 0.5rem;
      box-sizing: border-box;
    }

    .chat-list-item {
      display: flex;
      align-items: center;
      width: 100%;
      border-radius: 1rem;
      cursor: pointer;
      padding: 0.25rem 0.5rem 0.25rem 1rem;
      margin-bottom: 0.25rem;
      box-sizing: border-box;
      transition: all 0.25s;

      .chat-list-text {
        width: 100%;
        font-size: 1rem;
        overflow: auto;

        .chat-list-title {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          margin-bottom: 0.5rem;
        }

        .chat-list-time {
          color: var(--theme-light-A);
          font-size: 0.8rem;
        }
      }
    }

    .chat-list-item:hover {
      background-color: var(--theme-light-B);
    }
  }
}

.chat-list-btn-box {
  display: flex;
  position: relative;
  align-items: center;
}

.chat-list-btn-box-chosen {
  background-color: var(--theme-light-C);
  border-radius: 1rem;
}

.chat-list-btn {
  padding: 0.25rem;
  font-size: 1.25rem;
  border-radius: 0.5rem;
  margin: 0.5rem;
  color: var(--theme-light-A);
  transition: all 0.25s;
}

.chat-list-btn:hover {
  transform: scale(1.1);
  background-color: var(--theme-light-B);
}

.chat-func-list-btn {
  display: flex;
  position: absolute;
  border-radius: 1rem;
  background-color: var(--theme-light-C);
  right: 100%;
  margin-right: 0.5rem;
}

.input-bar {
  background-color: var(--theme-light-D);
  border: 0.05rem solid var(--theme-light-B);
  font-size: 1rem;
  display: flex;
  align-items: center;
  width: 100%;
  border-radius: 1.5rem;
  color: var(--theme-light-A);
  margin-bottom: 0.5rem;
  box-sizing: border-box;
  padding-right: 1rem;

  .input-bar-icon {
    padding: 0.5rem 1rem;
    font-size: 1.5rem;
  }
}

.bottom-func-bar {
  display: flex;

  .bottom-func-btn {
    border: 0.05rem solid var(--theme-light-B);
    transition: all 0.25s;
    color: var(--theme-light-A);
    cursor: pointer;
    padding: 0.5rem 2rem;
    border-radius: 1.5rem;
    margin: 0 1rem;
    margin-top: 1rem;
  }

  .bottom-func-btn:hover {
    background-color: var(--theme-light-B);
  }
}

/* 输入框样式 */
::v-deep .el-input__inner {
  box-shadow: none;
  background-color: var(--theme-light-D);
}

::v-deep .el-input__wrapper {
  width: 100%;
  box-shadow: none;
  background-color: var(--theme-light-D);
  padding: 0;
}

::v-deep .el-input__wrapper.is-focus {
  box-shadow: none;
}

::v-deep .el-input .el-input__count .el-input__count-inner {
  background-color: var(--theme-light-D);
  font-size: 1rem;
  padding: 0 0.5rem;
}

@media only screen and (max-width: 450px) {
}
</style>
