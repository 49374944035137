// 国内用户操作API接口
import axios from "axios";
import userStore from "@/store";
import { ElMessage } from "element-plus";

// 创建axios示例
const request = axios.create({
  baseURL: process.env.VUE_APP_ZH_NODE_BASE_URL,
});

// 响应拦截器
request.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // 登录信息过期，退出登录
    console.log("错误信息为：", error);
    if (error.code === "ERR_NETWORK") {
      if (localStorage.getItem("userInfo")) {
        ElMessage.error("服务器连接失败");
        userStore.commit("setuserInfo", null);
        localStorage.removeItem("userToken");
        localStorage.removeItem("userInfo");
        location.reload();
      }
    }

    if (error.response.status === 403 || error.response.status === 401) {
      ElMessage.error("登录信息过期，请重新登陆");
      userStore.commit("setuserInfo", null);
      localStorage.removeItem("userToken");
      localStorage.removeItem("userInfo");
      location.reload();
    }

    throw error;
  }
);

/* ---------邮箱功能开始--------- */
// 邮箱注册
export function EmailRegister_(data) {
  return request({
    url: "/user/func/register/EmailRegister",
    method: "POST",
    data,
  });
}

// 邮箱登录
export function EmailLogin_(data) {
  return request({
    url: "/user/func/login/EmailLogin",
    method: "POST",
    data,
  });
}

// 获取验证码
export function GetEmailCheckCode_(data) {
  return request({
    url: "/user/func/GetEmailCheckCode",
    method: "POST",
    data,
  });
}

// 修改密码
export function ChangeUserPsw_(data) {
  const userToken = localStorage.getItem("userToken");
  return request({
    url: "/user/func/ChangeUserPsw",
    method: "POST",
    data,
    headers: {
      Authorization: `Bearer ${userToken}`, // 使用模板字符串设置授权头部
    },
  });
}

// 忘记密码
export function ForgetUserPsw_(data) {
  return request({
    url: "/user/func/ForgetUserPsw",
    method: "POST",
    data,
  });
}
/* ---------邮箱功能结束--------- */

/* ---------微信功能开始--------- */
// 获取微信登录二维码
export function GetWeChatQRCode_() {
  return request({
    url: "/user/func/login/GetWeChatQRCode",
    method: "POST",
  });
}

// 检查微信公众号订阅状态
export function CheckSubscriptionStatus_(data) {
  return request({
    url: "/user/func/login/CheckSubscriptionStatus",
    method: "POST",
    data,
  });
}

// 检查微信授权登录
export function CheckWeChatAuthLoginStatus_(data) {
  return request({
    url: "/user/func/login/CheckWeChatAuthLoginStatus",
    method: "POST",
    data,
  });
}
/* ---------微信功能结束--------- */

/* ---------用户提示词功能开始--------- */
// 获取用户所有提示词
export function GetUserAllPrompts_() {
  const userToken = localStorage.getItem("userToken");
  return request({
    url: "/user/prompt/GetUserAllPrompts",
    method: "POST",
    headers: {
      Authorization: `Bearer ${userToken}`, // 使用模板字符串设置授权头部
    },
  });
}

// 添加新的提示词
export function AddNewCollections_(data) {
  const userToken = localStorage.getItem("userToken");
  return request({
    url: "/user/prompt/AddNewCollections",
    method: "POST",
    headers: {
      Authorization: `Bearer ${userToken}`, // 使用模板字符串设置授权头部
    },
    data,
  });
}

// 修改用户提示词数据
export function ChangeUserPromptData_(data) {
  const userToken = localStorage.getItem("userToken");
  return request({
    url: "/user/prompt/ChangeUserPromptData",
    method: "POST",
    headers: {
      Authorization: `Bearer ${userToken}`, // 使用模板字符串设置授权头部
    },
    data,
  });
}

// 是否展示用户提示词
export function IsShowUserPrompt_(data) {
  const userToken = localStorage.getItem("userToken");
  return request({
    url: "/user/prompt/IsShowUserPrompt",
    method: "POST",
    headers: {
      Authorization: `Bearer ${userToken}`, // 使用模板字符串设置授权头部
    },
    data,
  });
}

// 删除用户提示词数据
export function DelUserPromptData_(data) {
  const userToken = localStorage.getItem("userToken");
  return request({
    url: "/user/prompt/DelUserPromptData",
    method: "POST",
    headers: {
      Authorization: `Bearer ${userToken}`, // 使用模板字符串设置授权头部
    },
    data,
  });
}

// 删除用户全部提示词数据
export function DelUserAllPromptData_(data) {
  const userToken = localStorage.getItem("userToken");
  return request({
    url: "/user/prompt/DelUserAllPromptData",
    method: "POST",
    headers: {
      Authorization: `Bearer ${userToken}`, // 使用模板字符串设置授权头部
    },
    data,
  });
}
/* ---------用户提示词功能结束--------- */

/* ---------用户其他功能开始--------- */
// 获取用户信息
export function GetUserInfo_() {
  const userToken = localStorage.getItem("userToken");
  return request({
    url: "/user/func/GetUserInfo",
    method: "POST",
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
  });
}

// 更换头像
export function ChangeAvatar_(data) {
  const userToken = localStorage.getItem("userToken");
  return request({
    url: "/user/func/ChangeAvatar",
    method: "POST",
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
    data,
  });
}

// 更换用户名
export function ChangeUserName_(data) {
  const userToken = localStorage.getItem("userToken");
  return request({
    url: "/user/func/ChangeUserName",
    method: "POST",
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
    data,
  });
}

// 获取用户订单记录
export function GetUerOrdersRecords_() {
  const userToken = localStorage.getItem("userToken");
  return request({
    url: "/user/func/GetUerOrdersRecords",
    method: "POST",
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
  });
}

// 扣除费用
export function DeExpense_(data) {
  const userToken = localStorage.getItem("userToken");
  return request({
    url: "/user/func/DeExpense",
    method: "POST",
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
    data,
  });
}

/* ---------用户其他功能结束--------- */
