<template>
  <transition name="fade-show">
    <div
      class="dialog-back"
      @keydown.esc="Cancel"
      tabindex="0"
      ref="dialogueBox"
    >
      <div class="dialog-box">
        <slot name="content"></slot>
        <div class="bottom-func-bar">
          <div class="bottom-func-btn" @click="Cancel">
            {{ props.cancelText }}
          </div>
          <div class="bottom-func-btn" @click="Confirm">
            {{ props.confirmText }}
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script setup>
import { defineProps, defineEmits } from "vue";
import { ref, onMounted } from "vue";

const props = defineProps({
  cancelText: {
    type: String,
    default: "取消",
  },
  confirmText: {
    type: String,
    default: "确认",
  },
});

const emit = defineEmits(["confirm", "cancel"]);

const Confirm = () => {
  emit("confirm");
};

const Cancel = () => {
  emit("cancel");
};

const dialogueBox = ref(null);

onMounted(() => {
  dialogueBox.value.focus();
});
</script>

<style scoped>
.dialog-back {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  padding: 0.5rem;
  box-sizing: border-box;

  .dialog-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--theme-light-D);
    width: 30rem;
    border-radius: 1.5rem;
    padding: 1rem;

    .bottom-func-bar {
      display: flex;
      justify-content: space-evenly;
      width: 100%;

      .bottom-func-btn {
        border: 0.05rem solid var(--theme-light-B);
        transition: all 0.25s;
        color: var(--theme-light-A);
        cursor: pointer;
        padding: 0.5rem 2rem;
        border-radius: 1.5rem;
      }

      .bottom-func-btn:hover {
        background-color: var(--theme-light-B);
      }
    }
  }
}

/* 来控制元素显隐时的过渡效果 */
.fade-show-enter-active,
.fade-show-leave-active {
  transition: opacity 0.5s;
}

/* 来设置初始和最终状态的透明度 */
.fade-show-enter-from,
.fade-show-leave-to {
  opacity: 0;
}

@media only screen and (max-width: 450px) {
  .dialog-back {
    .dialog-box {
      padding: 0.5rem;
    }
  }
}
</style>
