<template>
  <!-- 登陆界面组件 -->
  <LoginDialogue v-if="isShowLoginDialog"></LoginDialogue>
  <div class="app-body" :class="{ isHidden: isHideLeftBar }" v-else>
    <!-- 服务器消息对话框 -->
    <transition name="fade-show">
      <div
        class="news-box-back"
        v-if="
          isDataLoaded &&
          !isShowLoginDialog &&
          userNewsReadID !== serverNewsData.newsID
        "
      >
        <div class="news-box">
          <div class="news-title">True Science AI更新日志</div>
          <div class="news-box-top-bar"></div>
          <P class="news-content">{{ serverNewsData.newsContent }}</P>
          <div class="news-time">{{ serverNewsData.newsTime }}</div>
          <div class="read-btn" @click="ReadNews(serverNewsData.newsID)">
            已读
          </div>
        </div>
      </div>
    </transition>
    <!-- 侧边导航栏 -->
    <div class="left-navigation-bar-box">
      <!-- 顶部工具栏 -->
      <div class="top-func-box">真智AI</div>

      <!-- 页面导航选项栏 -->
      <div class="web-navigation-box">
        <router-link
          class="web-navigation"
          v-for="(item, index) in navigationList"
          :key="index"
          active-class="web-navigation-active"
          :to="item.to"
        >
          <el-icon size="1.5rem" style="padding: 0 1rem">
            <component :is="item.icon" />
          </el-icon>
          <p class="web-navigation-title">{{ item.title }}</p>
        </router-link>
      </div>
      <div class="create-new-box">
        <!-- 创建新的对话 -->
        <div
          class="create-new-btn-box"
          @click="AddUserDialoguesCache()"
          v-if="router.currentRoute.value.path === '/'"
        >
          <el-icon class="create-btn">
            <ChatLineSquare />
          </el-icon>
          <p class="create-btn-title">创建新的对话</p>
        </div>
        <!-- 创建新的画册 -->
        <div
          class="create-new-btn-box"
          @click="AddUserAlbums()"
          v-if="router.currentRoute.value.path === '/image'"
        >
          <el-icon class="create-btn">
            <ChatLineSquare />
          </el-icon>
          <p class="create-btn-title">创建新的画册</p>
        </div>
      </div>
      <!-- 对话内容栏-->
      <div class="left-conversationcache-content-box">
        <LeftChatCotent
          v-if="router.currentRoute.value.path === '/'"
        ></LeftChatCotent>
        <LeftImageCotent
          v-if="router.currentRoute.value.path === '/image'"
        ></LeftImageCotent>
      </div>
      <!-- 底部功能区域-->
      <div class="bottom-func-box" @mouseleave="showUserFunc = false">
        <!-- 其他功能区域 -->
        <transition name="fade-show">
          <div class="user-func-box" v-if="showUserFunc">
            <!-- 网站说明和计费标准 -->
            <div
              class="login-btn-box"
              @click="router.push({ path: '/priceview' })"
            >
              <el-icon class="login-btn">
                <Notification />
              </el-icon>
              <div class="login-btn-title">网站说明和计费标准</div>
            </div>
            <!-- 购买 -->
            <div
              class="login-btn-box"
              @click="router.push({ path: '/purchase' })"
            >
              <el-icon class="login-btn">
                <Goods />
              </el-icon>
              <div class="login-btn-title">购买</div>
            </div>
            <!-- 购买记录 -->
            <div
              class="login-btn-box"
              @click="router.push({ path: '/ordersrecords' })"
            >
              <el-icon class="login-btn">
                <Tickets />
              </el-icon>
              <div class="login-btn-title">购买记录</div>
            </div>
            <!-- 联系客服 -->
            <div class="login-btn-box" @click="contactUsVisible = true">
              <el-icon class="login-btn">
                <Phone />
              </el-icon>
              <div class="login-btn-title">联系客服</div>
            </div>
            <!-- 消息提醒 -->
            <div
              class="login-btn-box"
              @click="router.push({ path: '/servernews' })"
            >
              <el-icon class="login-btn">
                <Bell />
              </el-icon>
              <div class="login-btn-title">消息提醒</div>
            </div>
            <!-- 控制台 -->
            <div
              class="login-btn-box"
              :v-if="userInfo.userType == 'ADMIN'"
              @click="router.push({ path: '/controlpanel' })"
            >
              <el-icon class="login-btn">
                <Odometer />
              </el-icon>
              <div class="login-btn-title">控制台</div>
            </div>
            <!-- 个人中心 -->
            <div
              class="login-btn-box"
              @click="router.push({ path: '/personalcenter' })"
            >
              <el-icon class="login-btn">
                <User />
              </el-icon>
              <div class="login-btn-title">个人中心</div>
            </div>
            <!-- 更多 -->
            <div class="login-btn-box" @click="isShowMore = !isShowMore">
              <el-icon class="login-btn">
                <More />
              </el-icon>
              <div class="login-btn-title">更多</div>
            </div>
            <!-- 更多内容 -->
            <div
              style="display: flex; flex-direction: column; width: 100%"
              v-if="isShowMore"
            >
              <!-- 关于我们 -->
              <div class="login-btn-box" @click="OpenExternalLinkA()">
                <el-icon class="login-btn">
                  <House />
                </el-icon>
                <div class="login-btn-title">关于我们</div>
              </div>
              <!-- 协议 -->
              <div class="login-btn-box" @click="OpenExternalLinkB()">
                <el-icon class="login-btn">
                  <Reading />
                </el-icon>
                <div class="login-btn-title">协议</div>
              </div>
            </div>

            <!-- 退出登录 -->
            <div class="login-btn-box" @click="LogOut()">
              <el-icon class="login-btn">
                <ArrowLeftBold />
              </el-icon>
              <div class="login-btn-title">退出登录</div>
            </div>
          </div>
        </transition>
        <!-- 未登录的状态 -->
        <div
          class="login-btn-box"
          @click="showLoginDialog = true"
          v-if="!userInfo"
        >
          <el-icon class="login-btn">
            <User />
          </el-icon>
          <div class="login-btn-title">登录</div>
        </div>
        <!-- 登录之后的状态 -->
        <div class="login-btn-box" @mouseover="showUserFunc = true" v-else>
          <img class="user-avatar" :src="avatarUrl" />
          <div class="login-btn-title">
            {{ userInfo.userName }}
          </div>
          <img
            class="admin-icon"
            src="../assets/管理员.png"
            v-if="userInfo.userType === 'ADMIN'"
          />
          <img class="vip-icon" src="../assets/vip.png" v-if="userInfo.isVIP" />
        </div>
      </div>
    </div>
    <!-- 手机UI遮罩 -->
    <transition name="fade-show">
      <div
        class="right-wrap"
        v-if="!isHideLeftBar"
        @click="isHideLeftBar = !isHideLeftBar"
      ></div>
    </transition>
    <!-- 右边的路由页面 -->
    <div class="right-content">
      <!-- 侧边栏隐藏/显示按钮 -->
      <div class="left-top-box">
        <el-icon
          class="left-top-btn"
          @click="isHideLeftBar = !isHideLeftBar"
          v-if="!isHideLeftBar"
        >
          <Fold />
        </el-icon>
        <el-icon
          class="left-top-btn"
          @click="isHideLeftBar = !isHideLeftBar"
          v-else
        >
          <Expand />
        </el-icon>
        <el-icon
          class="left-top-btn"
          v-if="router.currentRoute.value.path === '/' && isHideLeftBar"
          @click="AddUserDialoguesCache()"
          ><Edit
        /></el-icon>
        <el-icon
          class="left-top-btn"
          v-if="router.currentRoute.value.path === '/image' && isHideLeftBar"
          @click="AddUserAlbums()"
          ><Edit
        /></el-icon>
      </div>

      <RouterView v-slot="{ Component }">
        <!-- 需要缓存 -->
        <KeepAlive>
          <component
            :is="Component"
            :key="$route.name"
            v-if="$route.meta.keepAlive"
          ></component>
        </KeepAlive>
        <!-- 不需要缓存 -->
        <component
          :is="Component"
          :key="$route.name"
          v-if="!$route.meta.keepAlive"
        ></component>
      </RouterView>
    </div>
    <!-- 联系客服 -->
    <ContactUs
      v-if="contactUsVisible"
      @close="contactUsVisible = false"
    ></ContactUs>
  </div>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount, computed, onBeforeMount } from "vue";
import router from "@/router";
import userStore from "@/store";
import LeftChatCotent from "../components/LeftChatCotent.vue";
import LeftImageCotent from "../components/LeftImageCotent.vue";
import LoginDialogue from "@/components/LoginDialogue.vue";
import ContactUs from "./ContactUs.vue";
import { ElMessage, ElNotification } from "element-plus";
import { GetUserInfo } from "@/utils/GetUserInfo";
import {
  AddUserDialoguesCache_,
  GetUserDialoguesCache_,
  AddUserAlbums_,
} from "../../api/SG_UserAPI";
import {
  GetServerNews_,
  GetUserNewsReadID_,
  ReadNews_,
} from "../../api/SG_ServerAPI";
import { CheckWeChatAuthLoginStatus_ } from "../../api/ZH_UserAPI";

/* ---------对话功能开始--------- */
let isAdding = ref(false);
// 创建新的对话
async function AddUserDialoguesCache() {
  try {
    if (!userInfo.value) {
      return (showLoginDialog.value = true);
    }

    if (isAdding.value) {
      return ElMessage.warning("正在创建对话，请稍后再试");
    }

    isAdding.value = true;

    const data = {
      AIsName: "",
      AIsID: "",
      Icon: "",
      description: "",
      prompt: "",
    };
    let response = await AddUserDialoguesCache_(data);
    const dialogueID = response.data.dialogueID;
    data.threadID = response.data.threadID;
    data.prompt = response.data.prompt;
    data.apiKey = response.data.apiKey;

    // 重载数据，更新视图
    response = await GetUserDialoguesCache_();
    userStore.commit("setdialoguesCache_array", response.data);

    // 清空对话列表
    userStore.commit("setuserConversationList", []);
    // 指向新建的对话
    userStore.commit("setdialoguesCache_chosenIndex", dialogueID);
    // 设置当前对话信息
    userStore.commit("setcurrentdialoguesCacheInfo", data);
    console.log("设置当前对话信息:", data);

    isAdding.value = false;
  } catch (error) {
    console.log(error);
    ElMessage.error("服务器连接失败");
    isAdding.value = false;
  }
}

// 创建新的画册
async function AddUserAlbums() {
  try {
    if (!userInfo.value) {
      return (showLoginDialog.value = true);
    }

    if (isAdding.value) {
      return ElMessage.warning("正在创建画册，请稍后再试");
    }

    isAdding.value = true;

    // 新建画册
    const data = {
      albumTitle: "新创建的画册",
    };
    let response = await AddUserAlbums_(data);
    const { albumID, createdTime } = response.data;
    data.createdTime = createdTime;
    data.albumID = albumID;

    console.log("新建数据：", data);

    // 更新视图
    let userAlbums = userStore.getters["getuserAlbums"].array;
    console.log(userAlbums);
    userAlbums.unshift(data);
    userStore.commit("setuserAlbums_array", userAlbums);

    // 清空图像列表
    userStore.commit("userImgList", []);
    // 指向新建的画册
    userStore.commit("setuserAlbums_chosenIndex", albumID);

    isAdding.value = false;
  } catch (error) {
    console.log(error);
    ElMessage.error("服务器连接失败");
    isAdding.value = false;
  }
}
/* ---------对话功能结束--------- */

/* ---------其他功能开始--------- */
// 关于我们
function OpenExternalLinkA() {
  window.open("https://superlovefuture.com/", "_blank");
}

// 协议
function OpenExternalLinkB() {
  window.open("https://superlovefuture.com/privacy.html", "_blank");
}

// 微信公众号显示
let contactUsVisible = ref(false);
// 用户工具栏
let showUserFunc = ref(false);
// 侧边栏隐藏
let isHideLeftBar = ref(false);
// 登录对话框
let showLoginDialog = ref(false);
// 展示更多
let isShowMore = ref(false);

// 页面导航路由列表
const navigationList = [
  {
    title: "AI问答",
    icon: "ChatDotSquare",
    to: "/",
  },
  {
    title: "AI绘图",
    icon: "Picture",
    to: "/image",
  },
  {
    title: "AI广场",
    icon: "ShoppingTrolley",
    to: "/aisqure",
  },
];

// 手机检测
function CheckIfMobile() {
  isHideLeftBar.value = window.innerWidth <= 450; // 你可以根据需要调整阈值
}

// 用户头像
let avatarUrl = computed(
  () =>
    `https://api.dicebear.com/9.x/pixel-art/svg?seed=${encodeURIComponent(
      userInfo.value.userAvatar
    )}`
);

let serverNewsData = ref({});
let userNewsReadID = ref(0);
let isDataLoaded = ref(false);
// 获取服务器消息
async function GetNews() {
  try {
    let response = await GetServerNews_();
    if (response.data.length !== 0) {
      serverNewsData.value = response.data[0];
    }

    response = await GetUserNewsReadID_();
    if (response.data.length !== 0) {
      userNewsReadID.value = response.data[0].readNewsID;
    }

    // 控制组件显示
    isDataLoaded.value = true;
  } catch (error) {
    console.log(error);
  }
}

// 消息已读
async function ReadNews(newsID) {
  try {
    await ReadNews_({ newsID });
    userNewsReadID.value = newsID;
  } catch (error) {
    console.log(error);
  }
}

/* ---------其他功能结束--------- */

/* ---------登录据操作开始--------- */
let userInfo = computed(() => userStore.getters.getuserInfo);
let isShowLoginDialog = ref(false);
// 退出登录
function LogOut() {
  localStorage.removeItem("userToken");
  localStorage.removeItem("userInfo");
  userStore.commit("setuserInfo", null);

  isShowLoginDialog.value = true;
}

// 是否订阅
let isSubscribe = ref(false);
// 检查微信授权登录
async function CheckWeChatAuthLoginStatus(code) {
  try {
    const data = { code };
    const response = await CheckWeChatAuthLoginStatus_(data);
    isSubscribe.value = response.data.isSubscribe;

    // 如果用户已订阅
    if (isSubscribe.value) {
      localStorage.setItem("userToken", response.data.userToken);
      await GetUserInfo();

      ElNotification({
        title: "登录成功",
        message: "快登录体验True Science AI吧！",
        type: "success",
      });

      location.reload();
    }
  } catch (error) {
    console.log(error);
  }
}
/* ---------登录据操作结束--------- */

/* ---------生命周期钩子开始--------- */
// 组件挂载完成
onMounted(async () => {
  // 检查是否有用户的缓存
  if (!localStorage.getItem("userInfo")) {
    isShowLoginDialog.value = true;
  } else {
    await GetUserInfo();
  }

  // 获取服务器消息和用户已读消息ID
  if (userInfo.value) {
    GetNews();
  }

  // 回调地址参数处理
  window.onload = function () {
    if (window.location.search) {
      // 创建 URLSearchParams 对象
      const params = new URLSearchParams(window.location.search);
      const code = params.get("code");
      const state = params.get("state");
      // 如果存在code，说明是微信登录回调
      if (state === "BinsGroup" && code) {
        CheckWeChatAuthLoginStatus(code);
      }
      // 去掉 URL 中的查询参数
      window.history.replaceState({}, document.title, window.location.pathname);
    }
  };
});

// 组件销毁之前
onBeforeUnmount(() => {
  window.removeEventListener("resize", CheckIfMobile());
});

// 组件挂载之前
onBeforeMount(() => {
  // 手机检测
  CheckIfMobile();
  window.addEventListener("resize", CheckIfMobile());
});
/* ---------生命周期钩子结束--------- */
</script>
<style scoped>
.app-body {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  color: var(--theme-light-A);
}

.news-box-back {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  padding: 0.5rem;
  box-sizing: border-box;

  .news-box {
    width: 40rem;
    background-color: var(--theme-light-C);
    display: flex;
    flex-direction: column;
    padding: 1rem;
    border-radius: 1rem;

    .news-title {
      font-size: 1.5rem;
      font-weight: bold;
    }

    .news-content {
      white-space: pre-line;
      font-size: 1rem;
    }

    .news-time {
      font-size: 1rem;
      text-align: end;
    }

    .read-btn {
      padding: 0.5rem 1rem;
      text-align: center;
      border: 0.05rem solid var(--theme-light-B);
      border-radius: 1rem;
      transition: all 0.25s;
      cursor: pointer;
    }

    .read-btn:hover {
      transform: scale(1.025);
      background-color: var(--theme-light-B);
    }
  }
}

.left-navigation-bar-box {
  width: 16rem;
  display: flex;
  flex-direction: column;
  background-color: var(--theme-light-C);
}

.top-func-box {
  text-align: center;
  padding: 0.5rem 0;
  font-weight: bold;
}

.web-navigation-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0 0.5rem;
  box-sizing: border-box;
}

.web-navigation {
  color: var(--theme-light-A);
  width: 100%;
  height: 2.5rem;
  display: flex;
  align-items: center;
  border-radius: 1rem;
  text-decoration: none;
  margin-bottom: 0.25rem;
  outline: none;
  transition: all 0.25s;
}

.web-navigation-active {
  background-color: var(--theme-light-B);
}

.web-navigation:hover {
  background-color: var(--theme-light-B);
}

.web-navigation-title {
  margin: 0;
  font-size: 1rem;
}

.create-new-box {
  width: 100%;
  padding: 0 0.5rem;
  box-sizing: border-box;
}

.create-new-btn-box {
  display: flex;
  align-items: center;
  border: 0.05rem solid var(--theme-light-B);
  border-radius: 1rem;
  cursor: pointer;
  margin-bottom: 0.25rem;
  padding: 0.25rem 0;
  transition: all 0.25s;
}

.create-new-btn-box:hover {
  background-color: var(--theme-light-B);
}

.right-wrap {
  width: 100%;
  height: 100%;
  top: 0;
  position: absolute;
  right: 0;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.5);
  display: none;
}

.right-content {
  width: calc(100% - 16rem);
  height: 100%;
  display: flex;
  position: absolute;
  transition: all 0.25s;
  right: 0;
  box-sizing: border-box;
  border-left: 0.1rem solid var(--theme-light-B);
}

.isHidden .right-content {
  width: 100%;
  border: none;
}

.left-top-box {
  display: flex;
  align-items: center;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 10;

  .left-top-btn {
    font-size: 1.75rem;
    margin-top: 0.5rem;
    margin-left: 0.5rem;
    color: var(--theme-light-A);
    cursor: pointer;
    transition: all 0.25s;
  }

  .left-top-btn:hover {
    transform: scale(1.05);
  }
}

.create-btn {
  padding: 0 1rem;
  font-size: 1.5rem;
}

.create-btn-title {
  margin: 0;
  font-size: 1rem;
}

.left-conversationcache-content-box {
  overflow: auto;
  flex: 1;
}

.chat-list-box {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.chat-list-scrollbar {
  width: 100%;
  display: flex;
  height: 100%;
  flex-direction: column;
  background-color: var(--theme-light-C);
  padding: 0 0.25rem;
}

.chat-list-item {
  display: flex;
  align-items: center;
  width: 100%;
  border-radius: 1rem;
  cursor: pointer;
  padding: 0.25rem 0.5rem 0.25rem 1rem;
  margin-bottom: 0.25rem;
  box-sizing: border-box;
}

.chat-list-item:hover {
  background-color: var(--theme-light-B);
}

.chat-list-text {
  width: 100%;
  font-size: 1rem;
  overflow: auto;
}

.chat-list-title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 0.75rem;
}

.chat-list-title-input {
  background: 0 0;
  font-size: 1rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  border: none;
}

.chat-list-btn-box {
  display: flex;
  position: relative;
  align-items: center;
}

.chat-list-btn {
  padding: 0.25rem;
  font-size: 1.25rem;
  border-radius: 0.5rem;
  margin: 0.5rem;
  color: var(--theme-light-A);
}

.bottom-func-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 0 0.5rem;
}

.chat-list-btn-box-chosen {
  background-color: var(--theme-light-C);
  border-radius: 1rem;
}

.chat-list-btn:hover {
  background-color: var(--theme-light-B);
}

.chat-func-list-btn {
  display: flex;
  position: absolute;
  border-radius: 1rem;
  background-color: var(--theme-light-C);
  right: 100%;
  margin-right: 0.5rem;
}

.rename-func-back {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(50, 50, 50, 0.5);
  width: 100%;
  height: 100%;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* 来控制元素显隐时的过渡效果 */
.fade-show-enter-active,
.fade-show-leave-active {
  transition: opacity 0.5s;
}

/* 来设置初始和最终状态的透明度 */
.fade-show-enter-from,
.fade-show-leave-to {
  opacity: 0;
}

.user-func-box {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--theme-light-A);
}

.login-btn-box {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 0.25rem;
  height: 2.5rem;
  font-size: 1rem;
  border-radius: 1rem;
  cursor: pointer;
  box-sizing: border-box;
  transition: all 0.25s;
}

.login-btn-box:nth-child(1) {
  margin-top: 0.25rem;
}

.login-btn-box:hover {
  background-color: var(--theme-light-B);
}

.login-btn {
  padding: 0 1rem;
  font-size: 1.5rem;
}

.user-avatar {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  margin: 0 0.5rem;
  border: 0.05rem solid var(--theme-light-B);
}

.login-btn-title {
  font-size: 1rem;
}

.admin-icon,
.vip-icon {
  height: 1rem;
  margin: 0 0.5rem;
}

/* 半屏适配 */
@media only screen and (max-width: 1000px) {
  .left-navigation-bar-box {
    position: absolute;
    z-index: 100;
    transition: all 0.25s;
    height: 100%;
    width: 18rem;
  }
  .isHidden .left-navigation-bar-box {
    transform: translateX(-18rem);
  }
  .right-wrap {
    display: flex;
  }
  .right-content {
    position: relative;
    width: 100%;
  }
}

/* 手机适配 */
@media only screen and (max-width: 450px) {
  .left-navigation-bar-box {
    position: absolute;
    z-index: 100;
    transition: all 0.25s;
    height: 100%;
    width: 18rem;
  }
  .isHidden .left-navigation-bar-box {
    transform: translateX(-18rem);
  }
  .right-wrap {
    display: flex;
  }
  .right-content {
    position: relative;
    width: 100%;
  }
}
</style>
