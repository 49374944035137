// 新加坡用户操作API接口
import axios from "axios";
import userStore from "@/store";
import { ElMessage } from "element-plus";

// 创建axios示例
const request = axios.create({
  baseURL: process.env.VUE_APP_SG_NODE_BASE_URL,
});

// 响应拦截器
request.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // 登录信息过期，退出登录
    if (error.response.status === 403 || error.response.status === 401) {
      ElMessage.error("登录信息过期，请重新登陆！");
      userStore.commit("setuserInfo", null);
      localStorage.removeItem("userToken");
      localStorage.removeItem("userInfo");
      location.reload();
    }
    throw error;
  }
);

/* ---------用户对话数据操作开始--------- */
// 获取用户对话缓存
export function GetUserDialoguesCache_() {
  const userToken = localStorage.getItem("userToken");
  return request({
    url: "/user/chat/GetUserDialoguesCache",
    method: "POST",
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
  });
}

// 删除用户对话缓存
export function DelUserDialoguesCache_(data) {
  const userToken = localStorage.getItem("userToken");
  return request({
    url: "/user/chat/DelUserDialoguesCache",
    method: "POST",
    data,
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
  });
}

// 新增用户对话缓存
export function AddUserDialoguesCache_(data) {
  const userToken = localStorage.getItem("userToken");
  return request({
    url: "/user/chat/AddUserDialoguesCache",
    method: "POST",
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
    data,
  });
}

// 修改用户对话缓存标题
export function ChangeUserDialoguesTitle_(data) {
  const userToken = localStorage.getItem("userToken");
  return request({
    url: "/user/chat/ChangeUserDialoguesTitle",
    method: "POST",
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
    data,
  });
}

// 获取用户对话列表
export function GetUserConversationList_(data) {
  const userToken = localStorage.getItem("userToken");
  return request({
    url: "/user/chat/GetUserConversationList",
    method: "POST",
    data,
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
  });
}
/* ---------用户对话数据操作结束--------- */

/* ---------用户图像数据操作开始--------- */
// 获取用户画册
export function GetUserAlbums_() {
  const userToken = localStorage.getItem("userToken");
  return request({
    url: "/user/img/GetUserAlbums",
    method: "POST",
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
  });
}

// 获取用户图像列表
export function GetUserImgList_(data) {
  return request({
    url: "/user/img/GetUserImgList",
    method: "POST",
    data,
  });
}

// 新增用户画册
export function AddUserAlbums_(data) {
  const userToken = localStorage.getItem("userToken");
  return request({
    url: "/user/img/AddUserAlbums",
    method: "POST",
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
    data,
  });
}

// 修改用户画册标题
export function ChangeUserAlbumsTitle_(data) {
  const userToken = localStorage.getItem("userToken");
  return request({
    url: "/user/img/ChangeUserAlbumsTitle",
    method: "POST",
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
    data,
  });
}

// 删除用户画册
export function DelUserAlbums_(data) {
  const userToken = localStorage.getItem("userToken");
  return request({
    url: "/user/img/DelUserAlbums",
    method: "POST",
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
    data,
  });
}

// 清空用户图像列表
export function ClearUserImgList_(data) {
  const userToken = localStorage.getItem("userToken");
  return request({
    url: "/user/img/ClearUserImgList",
    method: "POST",
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
    data,
  });
}
/* ---------用户图像数据操作开始--------- */

/* ---------调用对话模型开始--------- */
// 插入用户内容
export function InsertUserContent_(data) {
  const userToken = localStorage.getItem("userToken");
  return request({
    url: "/api/chat/InsertUserContent",
    method: "POST",
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
    data,
  });
}

// 插入回复内容
export function InsertAIContent_(data) {
  const userToken = localStorage.getItem("userToken");
  return request({
    url: "/api/chat/InsertAIContent",
    method: "POST",
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
    data,
  });
}

// 暂调用GPT
export function SuspendCallGPT_(data) {
  const userToken = localStorage.getItem("userToken");
  return request({
    url: "/api/chat/SuspendCallGPT",
    method: "POST",
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
    data,
  });
}
/* ---------调用对话模型结束--------- */

/* ---------调用图像模型开始--------- */
// 插入用户提示词
export function InsertUserPrompt_(data) {
  const userToken = localStorage.getItem("userToken");
  return request({
    url: "/api/img/InsertUserPrompt",
    method: "POST",
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
    data,
  });
}

// 调用图像生成
export function CallImgGenerate_(data) {
  const userToken = localStorage.getItem("userToken");
  return request({
    url: "/api/img/CallImgGenerate",
    method: "POST",
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
    data,
  });
}

// 插入图像数据
export function Insertbase64Data_(data) {
  const userToken = localStorage.getItem("userToken");
  return request({
    url: "/api/img/Insertbase64Data",
    method: "POST",
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
    data,
  });
}
/* ---------调用图像模型结束--------- */

/* ---------用户其他操作开始--------- */

/* ---------用户其他操作结束--------- */
