import { createStore } from "vuex";

// 创建一个 Vuex store 实例
export default createStore({
  state: {
    userInfo: false,
    userConversationList: [],
    dialoguesCache: {
      chosenIndex: null,
      array: [],
    },
    currentdialoguesCacheInfo: {},
    isLoading: false,
    isChanging: false,
    userImgList: [],
    userAlbums: {
      chosenIndex: null,
      array: [],
    },
  },
  mutations: {
    // 设置用户信息
    setuserInfo(state, info) {
      state.userInfo = info;
    },
    // 设置用户对话列表
    setuserConversationList(state, info) {
      state.userConversationList = info;
    },
    // 设置当前选择的对话缓存ID
    setdialoguesCache_chosenIndex(state, info) {
      state.dialoguesCache.chosenIndex = info;
    },
    // 设置对话缓存数组
    setdialoguesCache_array(state, info) {
      state.dialoguesCache.array = info;
    },
    // 设置当前对话缓存的信息
    setcurrentdialoguesCacheInfo(state, info) {
      state.currentdialoguesCacheInfo = info;
    },
    // 设置是否在加载中
    setisLoading(state, info) {
      state.isLoading = info;
    },
    // 设置是否切换对话
    setisChanging(state, info) {
      state.isChanging = info;
    },
    // 设置用户图像列表
    setuserImgList(state, info) {
      state.userImgList = info;
    },
    // 设置当前选择的图像缓存ID
    setuserAlbums_chosenIndex(state, info) {
      state.userAlbums.chosenIndex = info;
    },
    // 设置图像缓存数组
    setuserAlbums_array(state, info) {
      state.userAlbums.array = info;
    },
  },
  getters: {
    // 获取用户信息
    getuserInfo(state) {
      return state.userInfo;
    },
    // 获取用户对话列表
    getuserConversationList(state) {
      return state.userConversationList;
    },
    // 获取对话缓存
    getdialoguesCache(state) {
      return state.dialoguesCache;
    },
    // 获取对话缓存数组
    getcurrentdialoguesCacheInfo(state) {
      return state.currentdialoguesCacheInfo;
    },
    // 获取加载情况
    getisLoading(state) {
      return state.isLoading;
    },
    // 获取切换状态
    getisChanging(state) {
      return state.isChanging;
    },
    // 获取用户画册
    getuserAlbums(state) {
      return state.userAlbums;
    },
    // 获取用户图像列表
    getuserImgList(state) {
      return state.userImgList;
    },
  },
  actions: {},
});
